@import '../../const';


.tp-program {

    &__container {
        background-color: $primary-background-color;
    }

    @include blockMaxWidth;
    @include blockVPadding;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    width: 100%;

    & h4 {
        margin-block-end: 1.5em;
    }

    & > p {
        font-weight: 500;
        width: 50%;
        border-right: 5px solid $main-blue;
        align-self: flex-end;
        text-align: right;
        padding-right: 1.7em;
    }

    &__day {
        @include blockVPadding;
        @include space-btw(row);
        width: 100%;
        height: max-content;

        
    }

    &__text {
        width: 54%;
        height: 100%;

        & p {
            text-align: justify;            
        }

        & ul {
            margin-block-end: 2em;

            & > li {
                list-style: circle;
                margin-left: 2em;
                @include font-size-adaptive(24px);
            }
        }

        & h3 {
            margin-block-end: 1.5em;
        }
    }

    &__img {
        width: 44%;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    

    @media (max-width: map-get($breakpoints, md)) {
        &__day {flex-direction: column-reverse;}
        &__img {width: 100%;}
        &__text {
            width: 100%;
            & h3 {
                margin-block-start: 1.5em;
            }
        }
        & h2 {text-align: center;}
        & h4 {text-align: center;}
    }
    @media (max-width: map-get($breakpoints, sm)) {
        & > p {
            width: 100%;
        }
    }
}


// .tp-program__text ul {
//     margin: 35px 0px 35px 0px;
// }
// .tp-program__text ul li {
//     font-size: 22px;
//     margin-left: 2em;
// }

