@import '../../const';

.lama-proj__article {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: lighten($color: $main-blue, $amount: 42);
    @include blockVPadding;
    gap: 15px;

    & h2 {
        color: $dark-gray;
        margin-block-end: 1em;
        @include blockMaxWidth;
        text-align: center;
    }
}

.tp-about {
    &__container {
        background-color: $primary-background-color;
        width: 100%;
    }
    
    width: 100%;
    @include blockMaxWidth;
    margin: 0 auto;
    @include blockVPadding;
    @include space-btw(row);
    align-items: center;

    

    h2 {
        color: $main-blue;
    }

    &__main {
        display: flex;
        flex-direction: column;
        width: 55%;

        & ul {
            margin-left: 2em;
            margin-block-end: 2em;

            & > li {
                list-style-type: disc;
                @include font-size-adaptive(24px);
            }
        }
    }

    &__hit-container {
        width: 40%;
        gap: 10px;
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__hit {

        padding: 2em;
        background-color: #d6d6d6;
        width: 100%;
        text-align: center;
        transition: all 0.3s ease;

        & h3 {
            text-align: center;
            color: $main-blue;
            font-weight: 700;
        }

        & p {
            line-height: 1.35;
            & span {
                @include font-size-adaptive(20px);                             
            }
            & span:first-child {
                font-weight: 700;
            }
            margin-block-end: 0em;
            
        }

        &:hover {
            background-color: #e4e3e3;
            box-shadow: 2px 2px 2px #d6d6d6;
        }
       
    }

    &__price {
        @include font-size-adaptive(35px);
    }

    &__note {
        font-style: italic;
        @include font-size-adaptive(19px);
    }


    @media (max-width: map-get($breakpoints, sm)) {
        flex-direction: column;
        &__main {
            width: 100%;

            & h2 {
                text-align: center;
            }

            & p {
                text-align: justify;
            }
        }


        &__hit-container {
            width: 100%;
        }
    }
}