@import '../../const';

.about-page {       

    &:hover {
        .about-page__bg {
            filter: brightness(0.7);
        }
    }

    &__cover {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 100vh;
        position: relative;
        @include blockHPadding;
    }

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: top;        
        z-index: -1;
        filter: brightness(0.55);
        transition: filter 0.7s ease;
    }

    &__text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;        
        align-items: center;

        & h1 {
            text-align: center;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
            margin-block-end: 2em;
        }        
    }

    &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 10% 0 10%;
        @media (max-width: map-get($breakpoints, md)) {
            padding: 0 0 0 0;
        }    
        gap: 5em;
        margin-bottom: 2em;

    }

    &__about {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        @media (max-width: map-get($breakpoints, md)) {
            flex-direction: column;
        }
    }

    &__description {
        padding: 5%;
        flex: 5  0 350px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > h2 {
            color: $main-blue;
            @include font-size-adaptive(58px);
            text-align: center;
        }
    }
    &__owner {
        flex: 3 0 350px;
        display: flex;
        padding: 5%;
        // height: 200px;
        flex-direction: column;
        @media (max-width: map-get($breakpoints, md)) {
            flex-direction: row;
            justify-content: space-between;
            gap: 3%;
        }
        @media (max-width: map-get($breakpoints, xs)) {
            flex-direction: column;
            justify-content: space-between;
            gap: 3%;
            height: 100%;
        }

        & > img {
                width: 100%;
                margin-bottom: 5%;
                object-fit: cover;
                @media (max-width: map-get($breakpoints, md)) {
                    min-width: 40%;
                    flex: 1 1 auto;
                    margin: 0;
                }
            }    
    }

    &__ownerdesc {
        @media (max-width: map-get($breakpoints, md)) {
            flex: 5 1 1200px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;;
        }
        @media (max-width: map-get($breakpoints, xs)) {
            flex: 1 1 auto;
        }
        background-color: $secondary-background-color;
        padding: 1em;
        & > h3 {
            margin-bottom: 0.5em;
            color: $main-blue;
            text-align: center;
            font-weight: 600;
        }
        & > p {
            text-align: center;
            margin-block-end: 0.5em;
            @include font-size-adaptive(20px);
            line-height: 1.4em;
            font-weight: 400;
        }
    }

    &__photo {
        align-self: center;
        width: 80%;  
        text-align: center;      
        position: relative;
        @media (max-width: map-get($breakpoints, md)) {
            width: 90%;
            display: flex;
            flex-direction: column;
        }

        & img {
            width: 60%;
            text-align: center;
            @media (max-width: map-get($breakpoints, md)) {
                width: 100%;
                align-self: center;;
            }
        }

        & p {
            width: 25%;
            text-transform: uppercase;
            font-weight: 600;
            @include font-size-adaptive(17px);
            line-height: 1.5em;
            position: absolute;
            top: 40%;
            left: 7%;
            text-align: left;
            @media (max-width: map-get($breakpoints, md)) {
                align-self: center;
                position: relative;
                width: 100%;
                left: 0;
                margin-top: 1.5em;
            }
        }
    }
        
    



    
}

.vk-container {
    @include blockMaxWidth;
    padding-top: 1.5rem;
    padding-bottom: 3rem;
    margin: 0 auto;

    & h2 {
        text-align: center;
        margin-block-end: 1.5em;
    }
}