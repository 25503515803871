@import '../../styles.scss';


.locations-page {
    height: 100vh;
    width: 100%;

    iframe {
        height: 100%;
        width: 100%;
        border: none;
    }
}