
@import '../../const';

.asection {
    width: 100%;
    min-height: 300px;

    background-color: $primary-background-color;
    @include blockMaxWidth;

    display: flex;
    flex-direction: row;

    &__text {
        padding: 50px;
        flex: 1 1 980px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        & h3 {
            text-align: center;
            color: $dark-gray;
            margin-block-end: 1em;
        }

        & p {
            @include font-size-adaptive(19px);
            width: 90%;
            margin: 0 auto;
            line-height: 1.3;
            color: $dark-gray;
            font-weight: 500;
        }
    }

    &__img {
        flex: 1 1 300px;
        // height: 100%;
        background-color: #ccc;
        
        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @media (max-width: map-get($breakpoints, lg)) {
        &__text {padding: 35px;}
        min-height: 250px;
    }
    @media (max-width: map-get($breakpoints, md)) {
        &__text {padding: 25px;}
        min-height: 200px;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        flex-direction: column-reverse;
        &__text {
            padding: 15px;
            flex: 1 1 250px;
        }
        &__img {
            flex: 1 1 150px;
        }
        min-height: 150px;
    }
}