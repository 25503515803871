@import '../../const';



.gallery {
    margin-top: 120px;

    &__img {
        overflow: hidden;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
        }
    }

    &__big-photo {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(100, 100, 100, 0.7);
        
        z-index: 2;

        & img {
            width: 100%;
            height: 100%;  
            object-fit: contain;          
        }
    }

    &__block {
        width: 100%;
        @include blockHPadding;
        padding-top: 70px;
        padding-bottom: 70px;
        @media (max-width: map-get($breakpoints, lg)) {
            padding-top: 55px;
            padding-bottom: 55px;
        }
        @media (max-width: map-get($breakpoints, md)) {
            padding-top: 40px;
            padding-bottom: 40px;
        }
        @media (max-width: map-get($breakpoints, sm)) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
        @media (max-width: map-get($breakpoints, xs)) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
        @media (max-width: map-get($breakpoints, vs)) {
            padding-top: 10px;
            padding-bottom: 10px;
        }   
        display: flex;
        flex-direction: column;
    }

    

    &__title {
        width: 100%;
        text-align: center;
        & h2 {
            font-weight: 400;
            margin-block-end: 1em;
        }
    }

    &__photo-container {
        width: 100%;
        display: grid;
        grid-template-areas:    "a a b d"
                                "a a c d"
                                "a a c e"
                                "f f c e";
        grid-template-rows: repeat(4, 200px);
        gap: 5px;
        & .gallery__photo:nth-child(1) {grid-area: a;}
        & .gallery__photo:nth-child(2) {grid-area: b;}
        & .gallery__photo:nth-child(3) {grid-area: c;}
        & .gallery__photo:nth-child(4) {grid-area: d;}
        & .gallery__photo:nth-child(5) {grid-area: e;}
        & .gallery__photo:nth-child(6) {grid-area: f;}

        @media (max-width: map-get($breakpoints, sm)) {
            grid-template-areas:    "a b"
                                    "a c"
                                    "d d"
                                    "e f";
        }
    }


    &__photo {
        width: 100%;
        overflow: hidden;
        cursor: pointer;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.9s ease;
            
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}





.pb {
    width: 100%;
    // @include blockHPadding;
    @include blockVPadding;
    position: relative;
    background-color: $primary-background-color;
    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;   
        gap: 5px;    
    }

    &__img {
        overflow: hidden;
        & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            filter: brightness(0.6);
            transform: scale(1.3);
            transition: all 0.6s ease;
            &:hover {
                transform: scale(1);
                filter: brightness(1);
            }
        }
    }

    &__big-photo {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(100, 100, 100, 0.7);
        
        z-index: 2;

        & img {
            width: 100%;
            height: 100%;  
            object-fit: contain;          
        }
    }
}

