@import '../../const';

.main-logo {
        z-index: 2;
        width: 200px;
        height: 200px;
        position: absolute;
        top: 100px;
        right: 10%;
        background-image: radial-gradient(farthest-side at 50% 50%, $main-blue, $main-blue, rgba(255, 255, 255, 0.3));
        border-radius: 100%;
        padding: 30px;

        @media (max-width: map-get($breakpoints, lg)) {
            width: 180px;
            height: 180px;
        }
        @media (max-width: map-get($breakpoints, md)) {
            width: 165px;
            height: 165px;
        }
        @media (max-width: map-get($breakpoints, sm)) {
            width: 150px;
            height: 150px;
            padding: 15px;
            top: 70px;
        }
        @media (max-width: map-get($breakpoints, xs)) {
            width: 120px;
            height: 120px;
            padding: 15px;
            top: 50px;
        }
        @media (max-width: map-get($breakpoints, vs)) {
            width: 90px;
            height: 90px;
            padding: 10px;
            top: 55px;
        }

        img {
            width: 100%;
            height: 100%;
    }
}