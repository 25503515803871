@import '../../const';

.thankyou-page {
    width: 100%;
    height: 100%;

    @include blockVPadding;
    background-color: $main-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;


    & h3 {
        color: $primary-background-color;
        font-weight: 500;
        width: 100%;
        padding-left: 2em;
        padding-right: 2em;
        text-align: center;

        & > a {
        color: $primary-background-color;
        text-decoration: underline;
        font-weight: 500;
        font-style: italic;
        }
    }


    
}