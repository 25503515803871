@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v15/K88pR3goAWT7BTt32Z01mxJtnKITppOI_IvcXXDNrsc.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }


  @font-face {
    font-family: 'Raleway';
    src: local('Raleway'), url('../public/fonts/Raleway-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Open Sans';
    src: url('../public/fonts/OpenSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}






$main-blue: #4d95be;
$white: #fff;
$black: #000;
$dark-gray: #282828;

$primary-background-color: #fefefe;
$secondary-background-color: #eee;



$breakpoints: (    
    vs: 376px,
    xs: 423px,
    sm: 767px,
    md: 1023px,
    lg: 1439px
);

$header-height: (
    vs: 60px,
    xs: 70px,
    sm: 80px,
    md: 100px,
    lg: 120px,
    vl: 120px
);


@mixin blockVPadding {
    padding-top: 100px;
    padding-bottom: 100px;
    @media (max-width: map-get($breakpoints, lg)) {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media (max-width: map-get($breakpoints, md)) {
        padding-top: 65px;
        padding-bottom: 65px;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        padding-top: 47px;
        padding-bottom: 47px;
    }
    @media (max-width: map-get($breakpoints, xs)) {
        padding-top: 35px;
        padding-bottom: 35px;
    }
    @media (max-width: map-get($breakpoints, vs)) {
        padding-top: 25px;
        padding-bottom: 25px;
    }    
}

@mixin blockHPadding {
    padding-left: 270px;
    padding-right: 270px;
    @media (max-width: map-get($breakpoints, lg)) {
        padding-left: 200px;
        padding-right: 200px;
    }
    @media (max-width: map-get($breakpoints, md)) {
        padding-left: 100px;
        padding-right: 100px;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media (max-width: map-get($breakpoints, xs)) {
        padding-left: 35px;
        padding-right: 35px;
    }
    @media (max-width: map-get($breakpoints, vs)) {
        padding-left: 18px;
        padding-right: 18px;
    }    
}

@mixin blockPadding {
    @include blockHPadding;
    @include blockVPadding;
}

@mixin blockMaxWidth {
    max-width: 1280px;
    @media (max-width: map-get($breakpoints, lg)) {
        max-width: 920px;
    }
    @media (max-width: map-get($breakpoints, md)) {
        max-width: 700px;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        max-width: 400px;
    }
    @media (max-width: map-get($breakpoints, xs)) {
        max-width: 350px;
    }
    @media (max-width: map-get($breakpoints, vs)) {
        max-width: 315px;
    }    
}

@mixin blockInternalMarginTop {
    margin-top: 75px;
    @media (max-width: map-get($breakpoints, lg)) {
        margin-top: 60px;
    }
    @media (max-width: map-get($breakpoints, md)) {
        margin-top: 50px;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        margin-top: 35px;
    }
    @media (max-width: map-get($breakpoints, xs)) {
        margin-top: 25px;
    }
    @media (max-width: map-get($breakpoints, vs)) {
        margin-top: 15px;
    }    
}

@mixin space-btw($direction) {
    display: flex;
    flex-direction: $direction;
    justify-content: space-between;
}

@function footer-img-margin($img-width) {
    @return calc($img-width / 4);
}

@mixin footer-img-params($img-width) {
    height: $img-width;
    width: $img-width;
    margin-left: footer-img-margin($img-width);
}

@function font-size-calc($base-size, $ratio) {
    @return calc($base-size * $ratio);
}

@mixin font-size-adaptive($base-size) {
    font-size: $base-size;
    @media (max-width: map-get($breakpoints, lg)) {
        font-size: calc($base-size / 1.1) ;
    }
    @media (max-width: map-get($breakpoints, md)) {
        font-size: calc($base-size / 1.3) ;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        font-size: calc($base-size / 1.4) ;
    }
    @media (max-width: map-get($breakpoints, xs)) {
        font-size: calc($base-size / 1.6) ;
    }
    @media (max-width: map-get($breakpoints, vs)) {
        font-size: calc($base-size / 1.7) ;
    }   
}

@mixin font-big-size-adaptive($base-size, $ratio) {
    font-size: $base-size;
    @media (max-width: map-get($breakpoints, lg)) {
        font-size: calc($base-size / 1.1 / $ratio) ;
    }
    @media (max-width: map-get($breakpoints, md)) {
        font-size: calc($base-size / 1.2 / $ratio) ;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        font-size: calc($base-size / 1.4 / $ratio) ;
    }
    @media (max-width: map-get($breakpoints, xs)) {
        font-size: calc($base-size / 1.6 / $ratio) ;
    }
    @media (max-width: map-get($breakpoints, vs)) {
        font-size: calc($base-size / 1.7 / $ratio) ;
    }   
}


html, body, #app, #root {
    height: 100%;
    width: 100%;
}



body {
    padding: 0;
    margin: 0;
}

body.lock {
    overflow: hidden;
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
    transition: all 0.3s ease 0s;
}

a {
    text-decoration: none;
    color: #000;
}


p {
    display: block;
    margin-block-start: 0;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    @include font-size-adaptive(24px)
}

h1, h2, h3 {
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

h1 {
    @include font-big-size-adaptive(75px, 1.5);
    font-weight: 400;
    margin-block-start: 0;
    margin-block-end: 0.3em;
}

h2 {
    @include font-size-adaptive(58px);
    margin-block-end: 0.2em;
}

h3 {
    @include font-size-adaptive(35px);
    font-weight: 400;
    margin-block-end: 0.1em;
}

h4 {
    @include font-size-adaptive(24px);
    font-weight: 500;
}

ul, li {
    padding: 0;
    margin: 0;
    list-style: none;
}

// #root {
//     min-height: 100vh;
// }


.main-button {
    position: relative;
    background-color: $main-blue;
    padding: 0.8em;
    border: 0;
    color: #fff;
    box-shadow: 2px 2px 2px #000000;
    @include font-size-adaptive(18px);
    font-weight: 700;
    transition: all 0.4s ease;
    cursor: pointer;

    &:hover {
        background-color: rgb(165, 190, 215);
        color: rgb(40, 65, 110);
    }

    &:active {
        box-shadow: none;
        top: 2px;
        left: 2px;
    }
}