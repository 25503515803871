@import '../../const';


.wide-cards {
    @include blockVPadding;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 35px;
    @media (max-width: map-get($breakpoints, lg)) {
        gap: 25px;
    }
    @media (max-width: map-get($breakpoints, md)) {
        gap: 15px;
    }

    &__card {
        width: 100%;
        border: 1px solid rgb(189, 189, 189);
        border-radius: 4px;
        border-left: none;
        border-right: none;
        cursor: pointer;
        background-color: $primary-background-color;
        transition: background-color 0.7s ease;

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 300px;

        ::-webkit-scrollbar {
            width: 4px;
            background-color: darken($color: $main-blue, $amount: 0);
        }
        
        ::-webkit-scrollbar-thumb {
            background-color: darken($color: $main-blue, $amount: 15);
            border-radius: 9em;
            // box-shadow: inset 1px 1px 10px #6b6b6b;
        }
        
        ::-webkit-scrollbar-thumb:hover {
            background-color: #303030;
        }



        @media(min-width: 768px) {
            .wide-cards &:nth-child(even) {
                flex-direction: row-reverse;
                justify-content: end;
                .wide-cards__img {
                    border-right: none;
                    border-left: 8px solid $main-blue;
                }
                .wide-cards__title {
                    align-self: flex-end;
                }
                p {
                    text-align: right;
                }
                .wide-cards__info {
                    flex-direction: row-reverse;
                    align-items: flex-end;
                }
                .wide-cards__cost {
                    align-items: flex-end;
                }
                .wide-cards__description h3 {
                    align-self: flex-end;
                }
            }        
        }



        @media (max-width: map-get($breakpoints, lg)) {
            height: 270px;
        }
        @media (max-width: map-get($breakpoints, md)) {
            height: 230px;
            .wide-cards__description {
                padding: 15px;
            }
        }
        @media (max-width: map-get($breakpoints, sm)) {
            flex-direction: column;
            justify-content: start;
            height: 100%;
            .wide-cards__img {
                border: none;
                width: 100%;
                height: 300px;
            }
            .wide-cards__description {
                width: 100%;
                padding: 15px;
                align-items: stretch;
                p {
                    text-align: justify;
                }
            }
        }
        @media (max-width: map-get($breakpoints, xs)) {
        }
        @media (max-width: map-get($breakpoints, vs)) {
            
        }    


        &:hover {
            background-color: #e6e6e6;
        }
    }

    &__img {
        height: 100%;
        width: 28%;
        border-right: 8px solid $main-blue;
        overflow: hidden;


        & img {
            height: 100%;
            width: 100%;
            vertical-align: middle;
            object-fit: cover;
        }        
    }

    &__description {
        width: 75%;
        min-height: 100%;
        overflow: auto;
        padding: 30px;
        @include space-btw(column);
        align-items: flex-start;

        & p {
            @include font-size-adaptive(17px);
            align-self: flex-start;
            width: 100%;
            font-weight: 500;
            line-height: 1.4em; 
        }

        & a {
            &:hover {
                color: $main-blue;
            }
        }

        & h3 {
        @include font-size-adaptive(30px);
        font-weight: 700;
        text-transform: uppercase;
        margin-block-end: 0.5em;
        }
    }


    &__bottom {
        @include space-btw(column);
        width: 100%;
        }

    &__bold {
        display: flex;
        flex-direction: row;
        min-width: 30%;
        @include font-size-adaptive(24px);
        font-weight: 700;
        }

    &__pale {
            @include font-size-adaptive(17px);
            color: #979797;
        }

    &__sections {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        & button {
            background-color: rgb(165, 190, 215);
            &.active {       
                background-color: $main-blue;         
                box-shadow: none;
                top: 2px;
                left: 2px;
            }
        }
    }

}
