@import '../../const';



.page-container {
    
        &__content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            @include blockMaxWidth;
            margin: 0 auto;
            padding-top: 5em;
            overflow: hidden;
        }

        &__title {
            position: relative;            

            & h1 {
                position: relative;
                text-transform: uppercase;
                font-weight: 500;
                z-index: 1;

                // &::after {
                //     content: 'ЭКСПЕДИЦИИ';
                //     position: absolute;
                //     left: 0.4em;
                //     top: -0.4em;
                //     @include font-size-adaptive(105px);
                //     text-transform: uppercase;
                //     font-weight: 700;
                //     color: darken($secondary-background-color, 10%);
                //     z-index: -1;                
                // }
            }

            & h3 {
                font-weight: 400;
                color: #7c7c7c;
                // margin-block-end: 1.5em;
            }

        }

        & h2 {
            @include font-size-adaptive(40px);
            margin-bottom: 1em;
        }
}

