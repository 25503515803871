@import '../../../const';



.tp-cover {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    position: relative;
    @include blockHPadding;
    

    &:hover {
        .tp-cover__bg {
            filter: brightness(0.7);
        }
    }

    &__bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        z-index: -1;
        filter: brightness(0.55);
        transition: filter 0.7s ease;
    }

    &__text {
        width: 100%;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;        
        align-items: center;

        & h1 {
            text-align: center;
            color: #fff;
            font-weight: 700;
            text-transform: uppercase;
            margin-block-end: 2em;
        }        
    }

    &__opt-block {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;        

        @media (max-width: map-get($breakpoints, sm)) {
            flex-direction: column;
        }
    }

    &__opt-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        @include font-size-adaptive(26px);
        & span {
            font-weight: 600;
            color: #fff;            
            text-transform: uppercase;
        }

        & img {
            align-self: baseline;
            filter: invert(1);
            height: 2em;
            width: 2em;
            margin-right: 2em;
        }
    }
    
}





.tp-about {
    &__container {
        background-color: $primary-background-color;
        width: 100%;
    }
    
    width: 100%;
    @include blockMaxWidth;
    margin: 0 auto;
    @include blockVPadding;
    @include space-btw(row);
    align-items: center;

    

    h2 {
        color: $main-blue;
    }

    &__main {
        display: flex;
        flex-direction: column;
        width: 55%;
        align-self: flex-start;

        & ul {
            margin-left: 2em;
            margin-block-end: 2em;

            & > li {
                list-style-type: disc;
                @include font-size-adaptive(24px);
            }
        }
    }

    &__hit-container {
        width: 40%;
        gap: 10px;
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__hit {

        padding: 2em;
        background-color: #d6d6d6;
        width: 100%;
        text-align: center;
        transition: all 0.3s ease;

        & h3 {
            text-align: center;
            color: $main-blue;
            font-weight: 700;
        }

        & p {
            line-height: 1.35;
            & span {
                @include font-size-adaptive(20px);                             
            }
            & span:first-child {
                font-weight: 700;
            }
            margin-block-end: 0em;
            
        }

        &:hover {
            background-color: #e4e3e3;
            box-shadow: 2px 2px 2px #d6d6d6;
        }
       
    }

    &__price {
        @include font-size-adaptive(35px);
    }

    &__note {
        font-style: italic;
        @include font-size-adaptive(19px);
    }


    @media (max-width: map-get($breakpoints, sm)) {
        flex-direction: column;
        &__main {
            width: 100%;

            & h2 {
                text-align: center;
            }

            & p {
                text-align: justify;
            }
        }


        &__hit-container {
            width: 100%;
        }
    }
}

// .tp-about__main ul>li {
//     font-size: 24px;
//     margin-left: 2em;
// }



.tp-aux {
    &__container {
        width: 100%;
        background-color: $main-blue;
        @include blockHPadding;
        @include blockVPadding;
    }

    & h2 {
        color: lighten($main-blue, 45%);
        text-align: center;
    }

    & p {
        text-align: justify;
        color: lighten($main-blue, 45%);

        & a {
            text-decoration: underline;
            color: lighten($main-blue, 45%);
        }
    }



    & > ul {
        margin-block-start: 2em;

        & > li {
            list-style-type: circle;
            color: #fff;
            @include font-size-adaptive(24px);
            line-height: 1.4;
            margin-left: 2em;

            & a {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
}


.tp-program {

    &__container {
        background-color: $primary-background-color;
    }

    @include blockMaxWidth;
    @include blockVPadding;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    width: 100%;

    & h4 {
        margin-block-end: 1.5em;
    }

    & > p {
        font-weight: 500;
        width: 50%;
        border-right: 5px solid $main-blue;
        align-self: flex-end;
        text-align: right;
        padding-right: 1.7em;
    }

    &__day {
        @include blockVPadding;
        @include space-btw(row);
        width: 100%;
        height: max-content;

        
    }

    &__text {
        width: 54%;
        height: 100%;

        & p {
            text-align: justify;            
        }

        & ul {
            margin-block-end: 2em;

            & > li {
                list-style: circle;
                margin-left: 2em;
                @include font-size-adaptive(24px);
            }
        }

        & h3 {
            margin-block-end: 1.5em;
        }
    }

    &__img {
        width: 44%;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    

    @media (max-width: map-get($breakpoints, md)) {
        &__day {flex-direction: column-reverse;}
        &__img {width: 100%;}
        &__text {
            width: 100%;
            & h3 {
                margin-block-start: 1.5em;
            }
        }
        & h2 {text-align: center;}
        & h4 {text-align: center;}
    }
    @media (max-width: map-get($breakpoints, sm)) {
        & > p {
            width: 100%;
        }
    }
}


// .tp-program__text ul {
//     margin: 35px 0px 35px 0px;
// }
// .tp-program__text ul li {
//     font-size: 22px;
//     margin-left: 2em;
// }



.tp-accmmdtn {
    width: 100%;
    background-color: $secondary-background-color;
    @include blockHPadding;
    @include blockVPadding;
    line-height: 1.6;

    &__title {
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
    }

    & ul {
        margin-left: 1em;
    }

    & > ul > li {
        list-style-image: url('./check.svg');
        @include font-size-adaptive(24px);
    }
    & > ul > li::marker{
        font-size: 1.6em;
        padding-left: 1em;    
    }
}


.pb {
    width: 100%;
    // @include blockHPadding;
    @include blockVPadding;
    position: relative;
    background-color: $primary-background-color;
    &__container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5px;    
    }

    &__img {
        overflow: hidden;
        & > img {
            width: 100%;
            // height: 100%;
            object-fit: cover;
            cursor: pointer;
            filter: brightness(0.6);
            transform: scale(1.3);
            transition: all 0.6s ease;
            &:hover {
                transform: scale(1);
                filter: brightness(1);
            }
        }
    }

    &__big-photo {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(100, 100, 100, 0.7);
        
        z-index: 2;

        & img {
            width: 100%;
            height: 100%;  
            object-fit: contain;         
        }
    }
}

